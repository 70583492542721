import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "magic-types"
    }}>{`Magic Types`}</h1>
    <hr></hr>
    <p>{`Hegel provides several of types which help to extract or create new types from existed. They may help to remove copy-pasted code. These types are available globally.`}</p>
    <p>{`Table of contents:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#classinstance"
        }}>{`$Class`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#excludetarget-whichshouldbeexcluded"
        }}>{`$Entries`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#excludetarget-whichshouldbeexcluded"
        }}>{`$Exclude`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#immutabletype"
        }}>{`$Immutable`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instanceofconstructor"
        }}>{`$InstanceOf`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#intersectiono1-o2-"
        }}>{`$Intersection`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#keysobj"
        }}>{`$Keys`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#omitobj-keys"
        }}>{`$Omit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#partialobj"
        }}>{`$Partial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pickobj-keys"
        }}>{`$Pick`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#propertytypeobj-key"
        }}>{`$PropertyType`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#returntypefntype-"
        }}>{`$ReturnType`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#softobj"
        }}>{`$Soft`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#strictobj"
        }}>{`$Strict`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#throwserrortype"
        }}>{`$Throws`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#typeofvariable"
        }}>{`$TypeOf`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#valuesobj"
        }}>{`$Values`}</a></li>
    </ul>
    <h2 {...{
      "id": "classinstance"
    }}>{`$Class<Instance`}{`>`}</h2>
    <p>{`$Class extract static type of constructor for given `}<inlineCode parentName="p">{`Instance`}</inlineCode>{` type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Animal {}
class Dog extends Animal {}
class Plant {}

let animalClass: $Class<Animal> = Animal;
let dogClass: $Class<Dog> = Dog;
let plantClass: $Class<Plant> = Plant;

// Because "class Dog" is subtype of "class Animal"
animalClass = dogClass; // 👌!

// Error: Type "class Animal" is incompatible with type "class Dog"
dogClass = animalClass;

// Error: Type "class Animal" is incompatible with type "class Plant"
plantClass = animalClass;

// Error: Type "class Dog" is incompatible with type "class Plant"
plantClass = dogClass;
`}</code></pre>
    <h2 {...{
      "id": "entriesobj"
    }}>{`$Entries<Obj`}{`>`}</h2>
    <p>{`$Entries<Obj`}{`>`}{` returns the union type of all properties and values types.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Admin = { name: string; age: number };

// Type of "property" is "['age', number] | ['name', string]"
const property: $Entries<Admin> = ["name", 4];
`}</code></pre>
    <h2 {...{
      "id": "excludetarget-whichshouldbeexcluded"
    }}>{`$Exclude<Target, WhichShouldBeExcluded`}{`>`}</h2>
    <p>{`Create a type by excluding from `}<inlineCode parentName="p">{`Target`}</inlineCode>{` all variants for which `}<inlineCode parentName="p">{`WhichShouldBeExcluded`}</inlineCode>{` will be a super type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Status = "Ok" | "Failed" | "Pending" | "Canceled";

// IntermediateStatuses = "Canceled" | "Panding"
type IntermediateStatuses = $Exclude<Status, "Ok" | "Failed">;

const intermediateStatuses: Array<$Exclude<Status, "Ok" | "Failed">> = [
  "Pending",
  "Canceled",
];

// Error: Type "['Failed']" is incompatible with type "...Array<'Canceled' | 'Pending'>"
intermediateStatuses.push("Failed");
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with TypeScript then you can be familiar with Exclude type.
`}<a parentName="p" {...{
          "href": "http://www.typescriptlang.org/docs/handbook/utility-types.html#excludetu"
        }}>{`TypeScript Exclude Type`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "immutabletype"
    }}>{`$Immutable<Type`}{`>`}</h2>
    <p>{`This magic type convert any type into immutable variant of this type which means that you will not be able to mutate this type or its properties. You can use it in several situations.`}</p>
    <ol>
      <li parentName="ol">{`Prevent variable mutation.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function manipulate(num: $Immutable<number>) {
  // Error: Attempt to mutate immutable type
  num = 15;
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Prevent object mutation.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const admin: $Immutable<{ name: string }> = { name: "Roy Trenneman" };

// Error: Attempt to mutate immutable type
admin.name = "Maurice Moss";

// Error: All parameters should be an object type. Only first parameter should mutable object type. 0 is not.
const resultAdmin = Object.assign(admin, { name: "Maurice Moss" });
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Prevent mutation of specific property.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const admin: { name: string; age: $Immutable<number> } = {
  name: "Roy Trenneman",
  age: 32,
};

admin.name = "Maurice Moss"; // 👌!

// Error: Attempt to mutate immutable type
admin.age = 33;

const maurice = Object.assign(admin, { name: "Maurice Moss" }); // 👌!

// Error: Attempt to mutate immutable property "age" in "{ age: $Immutable<number>, name: string }" type
const twelveAgeAdmin = Object.assign(admin, { age: 12 });
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Prevent array mutation.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const arr: $Immutable<Array<number>> = [1, 2, 3];

// Error: Attempt to mutate immutable type
arr[0] = 4;

// Error: Property "pop" does not exist in "$Immutable<Array<number>>"
arr.pop();
`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Also (as result of array immutability), $Immutable can give you an ability to use more specific array as subtype of existed.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const numbers: Array<number> = [1, 2, 3];

// Error: Type "Array<number>" is incompatible with type "Array<number | string>"
const numbersOrStrings: Array<number | string> = numbers;

const immutableNumbers: $Immutable<Array<number>> = [1, 2, 3];
const immutableNumbersOrStrings: $Immutable<Array<
  number | string
>> = immutableNumbers; // 👌!
`}</code></pre>
    <h2 {...{
      "id": "instanceofconstructor"
    }}>{`$InstanceOf<Constructor`}{`>`}</h2>
    <p>{`Create a type which is the instance type of a provided constructor `}<inlineCode parentName="p">{`Constructor`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Animal {}
class Dog extends Animal {}
class Plant {}

type AnimalClass = $Class<Animal>;
type DogClass = $Class<Dog>;
type PlantClass = $Class<Plant>;

// The same as "animal: Animal"
let animal: $InstanceOf<AnimalClass> = new Animal();

// The same as "dog: Dog"
let dog: $InstanceOf<DogClass> = new Dog();

// The same as "planet: Planet"
let plant: $InstanceOf<PlantClass> = new Plant();

// Because "Dog" is subtype of "Animal"
animal = dog; // 👌!

// Error: Type "Animal" is incompatible with type "Dog"
dog = animal;

// Error: Type "Animal" is incompatible with type "Plant"
plant = animal;

// Error: Type "Dog" is incompatible with type "Plant"
plant = dog;
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If your class is generic then you should provide type parameters after class:`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Container<T> {}

type ContainerClass = $Class<Container<unknown>>;

// "number" type as parameter after "ContainerClass"
const container: $InstanceOf<ContainerClass, number> = new Container<number>();
`}</code></pre>
    <h2 {...{
      "id": "intersectiono1-o2-"
    }}>{`$Intersection<O1, O2, ...`}{`>`}</h2>
    <p>{`$Intersection type merge multiple object types into new one. It means that you will get a new object types which will contain all properties of provided object types. If some objects contain the same properties then property of the most right object will be chosen.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type JsonSerializable = {
  toJSON: () => string;
};

type Base64Serializable = {
  toBase64: () => string;
};

type HashSerializable = {
  getHash: () => string;
};

const fullSerializable: $Intersection<
  JsonSerializable,
  Base64Serializable,
  HashSerializable
> = {
  toJSON() {
    return "";
  },
  toBase64() {
    return "";
  },
  getHash() {
    return "";
  },
};
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with TypeScript or Flow.js then you can be familiar with intersection type.
`}<a parentName="p" {...{
          "href": "http://www.typescriptlang.org/docs/handbook/advanced-types.html#intersection-types"
        }}>{`Intersection in TypeScript`}</a>{`, `}<a parentName="p" {...{
          "href": "https://flow.org/en/docs/types/intersections/#intersection-type-syntax-"
        }}>{`Intersection in Flow.js`}</a>{`;`}</p>
    </blockquote>
    <h2 {...{
      "id": "keysobj"
    }}>{`$Keys<Obj`}{`>`}</h2>
    <p>{`$Keys type return a `}<a parentName="p" {...{
        "href": "/docs/union-types"
      }}>{`Union Type`}</a>{` of all possible keys of provide `}<a parentName="p" {...{
        "href": "/docs/object-types"
      }}>{`Object Type`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Admin = { name: string; age: number };

// Type of "property" is "'age' | 'name'"
const property: $Keys<Admin> = "name";
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with Flow.js then you can be familiar with $Keys type.
`}<a parentName="p" {...{
          "href": "https://flow.org/en/docs/types/utilities/#toc-keys"
        }}>{`Flow.js $Keys`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "omitobj-keys"
    }}>{`$Omit<Obj, Keys`}{`>`}</h2>
    <p>{`Create an `}<a parentName="p" {...{
        "href": "/docs/object-types"
      }}>{`Object Type`}</a>{` by removing all properties provided as `}<a parentName="p" {...{
        "href": "/docs/union-types"
      }}>{`Union Type`}</a>{` by `}<inlineCode parentName="p">{`Keys`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Admin = { name: string; age: number; position: "admin" };

// Type of "admin" is "{ position: "admin" }"
const admin: $Omit<Admin, "age" | "name"> = { position: "admin" };
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with TypeScript then you can be familiar with Omit type.
`}<a parentName="p" {...{
          "href": "http://www.typescriptlang.org/docs/handbook/utility-types.html#omittk"
        }}>{`TypeScript Omit Type`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "partialobj"
    }}>{`$Partial<Obj`}{`>`}</h2>
    <p>{`Create an `}<a parentName="p" {...{
        "href": "/docs/object-types"
      }}>{`Object Type`}</a>{` by converting all properties of `}<a parentName="p" {...{
        "href": "/docs/object-types"
      }}>{`Object Type`}</a>{` `}<inlineCode parentName="p">{`Obj`}</inlineCode>{` into `}<a parentName="p" {...{
        "href": "/docs/optional-types"
      }}>{`Optional Type`}</a>{`;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type User = {
  name: string;
  age: number;
};

function patch(original: User, updates: $Partial<User>): User {
  return {
    name: updates.name || original.name,
    age: updates.age || original.age,
  };
}

const original: User = { name: "original name", age: 20 };

const olderUser = patch(original, { age: 22 });
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with TypeScript then you can be familiar with Partial type.
`}<a parentName="p" {...{
          "href": "http://www.typescriptlang.org/docs/handbook/utility-types.html#partialt"
        }}>{`TypeScript Partial Type`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "pickobj-keys"
    }}>{`$Pick<Obj, Keys`}{`>`}</h2>
    <p>{`Create an `}<a parentName="p" {...{
        "href": "/docs/object-types"
      }}>{`Object Type`}</a>{` by removing all properties which are not provided as `}<a parentName="p" {...{
        "href": "/docs/union-types"
      }}>{`Union Type`}</a>{` by `}<inlineCode parentName="p">{`Keys`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Admin = { name: string; age: number; position: "admin" };

// Type of "admin" is "{ name: string, age: number }"
const admin: $Pick<Admin, "age" | "name"> = { name: "Maurice Moss", age: 33 };
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with TypeScript then you can be familiar with Pick type.
`}<a parentName="p" {...{
          "href": "http://www.typescriptlang.org/docs/handbook/utility-types.html#picktk"
        }}>{`TypeScript Pick Type`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "propertytypeobj-key"
    }}>{`$PropertyType<Obj, Key`}{`>`}</h2>
    <p>{`Return a property with name `}<inlineCode parentName="p">{`Key`}</inlineCode>{` type from `}<inlineCode parentName="p">{`Obj`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Admin = { name: string; age: number; position: "admin" };

// Type of "age" variable is "number"
const age: $PropertyType<Admin, "age"> = 33;
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with Flow.js then you can be familiar with $PropertyType type.
`}<a parentName="p" {...{
          "href": "https://flow.org/en/docs/types/utilities/#toc-propertytype"
        }}>{`Flow.js $PropertyType`}</a>{`.
The main difference between Flow.js $PropertyType and Hegel $PropertyType is ability to use not only string literal as a second parameter of $PropertyType.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Usage of $PropertyType with type variable
type Admin = { name: string, age: number };

const propertyOf = <O: Object, K: $Keys<O>>(obj: O, key: K): $PropertyType<O, K> => obj[key];

const admin: Admin = { name: "Maurice Moss", age: 33 };

// Type of "age" variable is "number"
const age = propertyOf(admin, "age");
`}</code></pre>
    <h2 {...{
      "id": "returntypefntype-"
    }}>{`$ReturnType<FnType, ...`}{`>`}</h2>
    <p>{`Return a return type of provided `}<a parentName="p" {...{
        "href": "/docs/function-types"
      }}>{`Function Type`}</a>{` `}<inlineCode parentName="p">{`FnType`}</inlineCode>{` after applying generic arguments if `}<inlineCode parentName="p">{`FnType`}</inlineCode>{` is `}<a parentName="p" {...{
        "href": "/docs/generic-types"
      }}>{`Generic Function`}</a>{`;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type SimpleFunction = () => string;

// Type of variable "returnOfSimpleFunction" is "string"
const returnOfSimpleFunction: $ReturnType<SimpleFunction> = "";

type GenericFunction = <T>(T) => T;

// Type of variable "returnOfGenericFunction" is "string"
// Note that "string" was provided as type argument after original function type
const returnOfGenericFunction: $ReturnType<GenericFunction, string> = "";

// If you does not provide type argument for generic function you will got an error
// Error: Generic "<T>(T) => T" called with wrong number of arguments. Expect: 1, Actual: 0
const _: $ReturnType<GenericFunction> = "";
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with Flow.js then you can be familiar with $Call type. $ReturnType has the same semantic as $Call type in Flow.js
`}<a parentName="p" {...{
          "href": "https://flow.org/en/docs/types/utilities/#toc-call"
        }}>{`Flow.js $Call`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "softobj"
    }}>{`$Soft<Obj`}{`>`}</h2>
    <p>{`Convert any `}<a parentName="p" {...{
        "href": "/docs/object-types"
      }}>{`Object Type`}</a>{` in `}<a parentName="p" {...{
        "href": "/docs/object-types#strict-and-soft-object-types"
      }}>{`Soft Object Type`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Admin = { name: string; age: number };

// Error: Type "{ age: 33, name: 'Maurice Moss', test: 2 }" is incompatible with type "{ age: number, name: string }"
const strictAdmin: Admin = { name: "Maurice Moss", age: 33, test: 2 };

const softAdmin: $Soft<Admin> = { name: "Maurice Moss", age: 33, test: 2 }; // 👌!
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with Flow.js then you can be familiar with $Shape type. $Soft has the same semantic as $Shape type in Flow.js
`}<a parentName="p" {...{
          "href": "https://flow.org/en/docs/types/utilities/#toc-shape"
        }}>{`Flow.js $Shape`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "strictobj"
    }}>{`$Strict<Obj`}{`>`}</h2>
    <p>{`Convert any `}<a parentName="p" {...{
        "href": "/docs/object-types"
      }}>{`Object Type`}</a>{` in `}<a parentName="p" {...{
        "href": "/docs/object-types#strict-and-soft-object-types"
      }}>{`Strict Object Type`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Admin = { name: string, age: number, ... };

const softAdmin: Admin = { name: "Maurice Moss", age: 33, test: 2 }; // 👌!

// Error: Type "{ age: 33, name: 'Maurice Moss', test: 2 }" is incompatible with type "{ age: number, name: string }"
const strictAdmin: $Strict<Admin> = { name: "Maurice Moss", age: 33, test: 2 };
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with Flow.js then you can be familiar with $Exact type. $Soft has the same semantic as $Exact type in Flow.js
`}<a parentName="p" {...{
          "href": "https://flow.org/en/docs/types/utilities/#toc-exact"
        }}>{`Flow.js $Exact`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "throwserrortype"
    }}>{`$Throws<ErrorType`}{`>`}</h2>
    <p>{`$Throws type act like a `}<a parentName="p" {...{
        "href": "https://www.javatpoint.com/throws-keyword-and-difference-between-throw-and-throws"
      }}>{`Throws Statement in Java`}</a>{`. When you declare return type of your function as $Throws, you need to throw subtype of declared throws type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Error: Function should throw "SyntaxError" but throws nothing
function assert(something: unknown): $Throws<SyntaxError> {}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function assert(something: unknown): $Throws<SyntaxError> {
  // Error: Type "ReferenceError" is incompatible with type "SyntaxError"
  throw new ReferenceError();
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function assert(something: unknown): $Throws<ReferenceError> {
  throw new ReferenceError(); // 👌!
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function assert(something: unknown): $Throws<SyntaxError | ReferenceError> {
  throw new ReferenceError(); // 👌!
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function assert(something: unknown): $Throws<Error> {
  throw new ReferenceError(); // 👌!
}
`}</code></pre>
    <p>{`Also, if you call function which throws an error and this error is incompatible with declarated error type - Hegel will notify you about it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function assertIsNumber(something: unknown) {
  if (typeof something !== "number") {
    throw new Error("wrong parameter"); // 👌!
  }
}

function assertEntry(something: unknown): $Throws<TypeError> {
  // Error: Current function throws "Error" type which is incompatible with declareted throw type "TypeError"
  assertIsNumber(something);
}
`}</code></pre>
    <p>{`If you want to annotate that your function will throw something or will return something then you should use `}<a parentName="p" {...{
        "href": "/docs/union-types"
      }}>{`Union Type`}</a>{` with $Throws:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function processIfValid(a, b): number | $Throws<TypeError> {
  if (typeof a !== "number" || typeof b !== "number") {
    throw new TypeError("Function works only with numbers!");
  }
  return a + b;
}
`}</code></pre>
    <h2 {...{
      "id": "typeofvariable"
    }}>{`$TypeOf<Variable`}{`>`}</h2>
    <p>{`The $TypeOf type returns the Hegel type of a provided variable `}<inlineCode parentName="p">{`Variable`}</inlineCode>{`. Note, that $TypeOf is unique type because this is the only type which gets non-type argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const someVariable = [1, 2, 3];

// Type of variable "anotherVariable" is "[1, 2, 3]"
const anotherVariable: $TypeOf<someVariable> = someVariable;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Error: "$TypeOf" work only with identifier
const _: $TypeOf<number> = [];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Error: Variable "a" is not defined!
const _: $TypeOf<a> = [];
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with TypeScript or Flow.js then you can be familiar with "typeof" operator.
`}<a parentName="p" {...{
          "href": "http://www.typescriptlang.org/play/index.html?ssl=2&ssc=10&pln=2&pc=16#code/MYewdgzgLgBGCuBbARgUwE4QFwwILvQEMBPAHgRQwD4YBeGAbQEYAaGAJjYGYBdAbgBQoSLEI4oxAA6oQAMzhI0mOgsqY+QA"
        }}>{`TypeScript typeof operator`}</a>{` > `}<a parentName="p" {...{
          "href": "https://flow.org/en/docs/types/typeof/#toc-typeof-type-syntax"
        }}>{`Flow.js typeof operator`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "valuesobj"
    }}>{`$Values<Obj`}{`>`}</h2>
    <p>{`$Values<Obj`}{`>`}{` returns the union type of all properties value types.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Admin = { name: string; age: number };

// Type of "property" is "number | string"
const property: $Values<Admin> = "name";
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with Flow.js then you can be familiar with $Vaues type.
`}<a parentName="p" {...{
          "href": "https://flow.org/en/docs/types/utilities/#toc-values"
        }}>{`Flow.js $Values`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      